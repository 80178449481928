/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import CN from 'classnames'
import { useUserDetails } from 'framework/api/methods'
import {
  setLogoutModalHide,
  setUserDetails,
  setUserPermissions,
} from 'store/reducers/user/userSlice'

import { PopUpAlert } from 'components/atoms'
import { Footer } from 'components/common/Footer'
import { Header } from 'components/common/Header'
import { SideBar } from 'components/common/SideBar'

export interface MainLayoutProps {
  hasSubHeading?: boolean
  [x: string]: any
}

export const MainLayout: FC<MainLayoutProps> = ({
  children,
}: MainLayoutProps) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)
  const [isFullMenu, setIsFullMenu] = useState(true)
  const { isLogOutModalOpen }: any = useSelector<any>((state) => state.user)
  const [isActiveLogoutModal, setIsActiveLogoutModal] =
    useState(isLogOutModalOpen)
  const urlLocation = useLocation()
  const dispatch = useDispatch()
  const firstRoute = urlLocation.pathname.split('/')[1]
  const secondRoute = urlLocation.pathname.split('/')[2]
  const token = sessionStorage.getItem('token')
  /** API Call for Login function using react-query */
  const navigate = useNavigate()

  // /** Process the Login submission */
  const tokenDataOnSuccess = (data: any) => {
    dispatch(setUserDetails(data.profile))
    dispatch(setUserPermissions(data.permissions))
  }
  const tokenDataOnError = (error: any) => {
    sessionStorage.removeItem('token')
    navigate('/auth/login')
  }
  const { refetch } = useUserDetails({}, tokenDataOnSuccess, tokenDataOnError)

  useEffect(() => {
    token && refetch()
    setIsActiveLogoutModal(isLogOutModalOpen)
  }, [token, isLogOutModalOpen])

  const confirmLogout = () => {
    dispatch(setLogoutModalHide())
    sessionStorage.removeItem('token')
    navigate('/auth/login')
  }

  const cancelLogout = () => {
    setIsActiveLogoutModal(!isActiveLogoutModal)
    dispatch(setLogoutModalHide())
  }

  const initialObj = [
    {
      mainMenuId: 1,
      mainMenuItemKey: Math.random(),
      name: 'Dashboard',
      url: '/',
      icon: <i className='ri-dashboard-line'></i>,
      isSelected: false,
      hasSubmenu: false,
      isActive: true,
      subMenuItems: [],
    },
    {
      mainMenuId: 2,
      mainMenuItemKey: Math.random(),
      name: 'Order Consignment',
      url: '/order-consignment',
      icon: <i className='ri-truck-line'></i>,
      isSelected: false,
      hasSubmenu: true,
      isActive:
        userDetails?.userPermissions?.find(
          (item: any) => item === 'OrderConsignmentAccess',
        )?.length > 0
          ? true
          : false ||
            userDetails?.userPermissions?.find(
              (item: any) => item === 'BulkOrderConsignmentAccess',
            )?.length > 0
          ? true
          : false,
      subMenuItems: [
        {
          subMenuId: 1,
          subMenuItemKey: Math.random(),
          name: 'Single Order Consignment',
          url: '/order-consignment/single-order-consignment',
          icon: <i className='ri-bill-line'></i>,
          isSelected: false,
          isActive:
            userDetails?.userPermissions?.find(
              (item: any) => item === 'OrderConsignmentAccess',
            )?.length > 0
              ? true
              : false,
        },
        {
          subMenuId: 2,
          subMenuItemKey: Math.random(),
          name: 'Bulk Order Consignment',
          url: '/order-consignment/bulk-order-consignment',
          icon: <i className='ri-file-copy-2-line'></i>,
          isSelected: false,
          isActive:
            userDetails?.userPermissions?.find(
              (item: any) => item === 'BulkOrderConsignmentAccess',
            )?.length > 0
              ? true
              : false,
        },
        {
          subMenuId: 3,
          subMenuItemKey: Math.random(),
          name: 'Starshipit Manifesting',
          url: '/order-consignment/starshipit-manifesting',
          icon: <i className='ri-luggage-deposit-line'></i>,
          isSelected: false,
          isActive:
            userDetails?.userPermissions?.find(
              (item: any) => item === 'StarshipitManifestAccess',
            )?.length > 0
              ? true
              : false,
        },
        {
          subMenuId: 4,
          subMenuItemKey: Math.random(),
          name: 'MachShip Manifesting',
          url: '/order-consignment/machship-manifesting',
          icon: <i className='ri-luggage-deposit-line'></i>,
          isSelected: false,
          isActive:
            userDetails?.userPermissions?.find(
              (item: any) => item === 'MachShipManifestAccess',
            )?.length > 0
              ? true
              : false,
        },
        {
          subMenuId: 5,
          subMenuItemKey: Math.random(),
          name: 'Australia Post Manifesting',
          url: '/order-consignment/auspost-manifesting',
          icon: <i className='ri-luggage-deposit-line'></i>,
          isSelected: false,
          isActive:
            userDetails?.userPermissions?.find(
              (item: any) => item === 'AusPostManifestAccess',
            )?.length > 0
              ? true
              : false,
        },
      ],
    },
    {
      mainMenuId: 3,
      mainMenuItemKey: Math.random(),
      name: 'Customer Order',
      url: '/customer-order',
      icon: <i className='ri-shopping-cart-2-line'></i>,
      isSelected: false,
      hasSubmenu: true,
      isActive:
        userDetails?.userPermissions?.find(
          (item: any) =>
            item === 'PartnerOrderAccess' || item === 'FileVsOrderAccess',
        )?.length > 0
          ? true
          : false,
      subMenuItems: [
        {
          subMenuId: 1,
          subMenuItemKey: Math.random(),
          name: 'Orders',
          url: '/customer-order/orders',
          icon: <i className='ri-shopping-cart-2-line'></i>,
          isSelected: false,
          isActive:
            userDetails?.userPermissions?.find(
              (item: any) => item === 'PartnerOrderAccess',
            )?.length > 0
              ? true
              : false,
        },
        {
          subMenuId: 2,
          subMenuItemKey: Math.random(),
          name: 'File vs CustomerOrders',
          url: '/customer-order/file-vs-customer-order',
          icon: <i className='ri-file-transfer-line'></i>,
          isSelected: false,
          isActive:
            userDetails?.userPermissions?.find(
              (item: any) => item === 'FileVsOrderAccess',
            )?.length > 0
              ? true
              : false,
        },
      ],
    },
    {
      mainMenuId: 4,
      mainMenuItemKey: Math.random(),
      name: 'Stocks',
      url: '/stocks',
      icon: <i className='ri-luggage-cart-line'></i>,
      isSelected: false,
      hasSubmenu: true,
      isActive:
        userDetails?.userPermissions?.find(
          (item: any) =>
            item === 'StockOrderAccess' ||
            item === 'StockOnHandAccess' ||
            item === 'FileVsOrderAccess',
        )?.length > 0
          ? true
          : false,
      subMenuItems: [
        {
          subMenuId: 1,
          subMenuItemKey: Math.random(),
          name: 'Stock Orders',
          url: '/stocks/stock-orders',
          icon: <i className='ri-luggage-cart-line'></i>,
          isSelected: false,
          isActive:
            userDetails?.userPermissions?.find(
              (item: any) => item === 'StockOrderAccess',
            )?.length > 0
              ? true
              : false,
        },
        {
          subMenuId: 2,
          subMenuItemKey: Math.random(),
          name: 'Stock On Hand',
          url: '/stocks/stock-on-hand',
          icon: <i className='ri-hand-coin-line'></i>,
          isSelected: false,
          isActive:
            userDetails?.userPermissions?.find(
              (item: any) => item === 'StockOnHandAccess',
            )?.length > 0
              ? true
              : false,
        },
        {
          subMenuId: 3,
          subMenuItemKey: Math.random(),
          name: 'File vs Orders',
          url: '/stocks/file-vs-orders',
          icon: <i className='ri-file-transfer-line'></i>,
          isSelected: false,
          isActive:
            userDetails?.userPermissions?.find(
              (item: any) => item === 'FileVsOrderAccess',
            )?.length > 0
              ? true
              : false,
        },
      ],
    },
    {
      mainMenuId: 5,
      mainMenuItemKey: Math.random(),
      name: 'Partners',
      url: '/partners',
      icon: <i className='ri-service-line'></i>,
      isSelected: false,
      hasSubmenu: false,
      isActive:
        userDetails?.userPermissions?.find(
          (item: any) => item === 'ConfigurationAccess',
        )?.length > 0
          ? true
          : false,
      subMenuItems: [],
    },
    {
      mainMenuId: 6,
      mainMenuItemKey: Math.random(),
      name: 'Warehouse',
      url: '/warehouse',
      icon: <i className='ri-home-3-line'></i>,
      isSelected: false,
      hasSubmenu: false,
      isActive:
        userDetails?.userPermissions?.find(
          (item: any) => item === 'WarehouseAccess',
        )?.length > 0
          ? true
          : false,
      subMenuItems: [],
    },
    {
      mainMenuId: 7,
      mainMenuItemKey: Math.random(),
      name: 'Users',
      url: '/users',
      icon: <i className='ri-team-line'></i>,
      isSelected: false,
      hasSubmenu: true,
      isActive:
        userDetails?.userPermissions?.find((item: any) => item === 'UserAccess')
          ?.length > 0
          ? true
          : false,
      subMenuItems: [
        {
          subMenuId: 1,
          subMenuItemKey: Math.random(),
          name: 'User List',
          url: '/users/user-List',
          icon: <i className='ri-group-2-line'></i>,
          isSelected: false,
          isActive: true,
        },
        {
          subMenuId: 2,
          subMenuItemKey: Math.random(),
          name: 'User Roles',
          url: '/users/user-roles',
          icon: <i className='ri-user-settings-line'></i>,
          isSelected: false,
          isActive: true,
        },
        {
          subMenuId: 3,
          subMenuItemKey: Math.random(),
          name: 'User Privileges',
          url: '/users/user-privileges',
          icon: <i className='ri-user-star-line'></i>,
          isSelected: false,
          isActive: true,
        },
      ],
    },
    {
      mainMenuId: 8,
      mainMenuItemKey: Math.random(),
      name: 'Warehouse Allocation',
      url: '/warehouse-allocation',
      icon: <i className='ri-bank-line'></i>,
      isSelected: false,
      hasSubmenu: true,
      isActive:
        userDetails?.userPermissions?.find(
          (item: any) =>
            item === 'GenericTaskCreationAccess' ||
            item === 'AgencyTaskCreationAccess',
        )?.length > 0
          ? true
          : false,
      subMenuItems: [
        {
          subMenuId: 1,
          subMenuItemKey: Math.random(),
          name: 'Generic Task Creation',
          url: '/warehouse-allocation/generic-task-creation',
          icon: <i className='ri-task-line'></i>,
          isSelected: false,
          isActive:
            userDetails?.userPermissions?.find(
              (item: any) => item === 'GenericTaskCreationAccess',
            )?.length > 0
              ? true
              : false,
        },
        {
          subMenuId: 2,
          subMenuItemKey: Math.random(),
          name: 'Agency Task Creation',
          url: '/warehouse-allocation/agency-task-creation',
          icon: <i className='ri-task-line'></i>,
          isSelected: false,
          isActive:
            userDetails?.userPermissions?.find(
              (item: any) => item === 'AgencyTaskCreationAccess',
            )?.length > 0
              ? true
              : false,
        },
      ],
    },
    {
      mainMenuId: 10,
      mainMenuItemKey: Math.random(),
      name: 'Configuration',
      url: '/configuration',
      icon: <i className='ri-settings-5-line'></i>,
      isSelected: false,
      hasSubmenu: true,
      isActive:
        userDetails?.userPermissions?.find(
          (item: any) =>
            item === 'CutOffTimeAccess' ||
            item === 'OrderCutOffAccess' ||
            item === 'CutOffTimeDeleteAccess',
        )?.length > 0
          ? true
          : false,
      subMenuItems: [
        {
          subMenuId: 1,
          subMenuItemKey: Math.random(),
          name: 'Courier Cut-Off Time',
          url: '/configuration/courier-cutoff-time',
          icon: <i className='ri-timer-flash-line'></i>,
          isSelected: false,
          isActive:
            userDetails?.userPermissions?.find(
              (item: any) => item === 'CutOffTimeAccess',
            )?.length > 0
              ? true
              : false,
        },
        {
          subMenuId: 2,
          subMenuItemKey: Math.random(),
          name: 'Order Cut-off Time',
          url: '/configuration/order-cutoff-time',
          icon: <i className='ri-shopping-basket-2-line'></i>,
          isSelected: false,
          isActive:
            userDetails?.userPermissions?.find(
              (item: any) => item === 'OrderCutOffAccess',
            )?.length > 0
              ? true
              : false,
        },
      ],
    },
    {
      mainMenuId: 9,
      mainMenuItemKey: Math.random(),
      name: 'Billing & Invoices',
      url: '/billingModule',
      icon: <i className='ri-shopping-cart-2-line'></i>,
      isSelected: false,
      hasSubmenu: false,
      isActive:
        userDetails?.userPermissions?.find(
          (item: any) =>
            item === 'InvoiceViewAccess' || item === 'InvoiceEditAccess',
        )?.length > 0
          ? true
          : false,
      subMenuItems: [],
    },
  ]

  return (
    <>
      <div className='w-full h-screen'>
        <SideBar
          isFullMenu={isFullMenu}
          firstRoute={firstRoute}
          secondRoute={secondRoute}
          menuItemList={initialObj}
          userDetails={userDetails}
        />
        <Header
          isFullMenu={isFullMenu}
          onToggleButtonClick={() => {
            setIsFullMenu(!isFullMenu)
          }}
          userDetails={userDetails}
        />
        <Footer isFullMenu={isFullMenu} />
        <div
          className={CN(
            'main-layout__content z-10 fixed left-[280px] top-[76px] w-[calc(100%-280px)] h-[calc(100%-136px)]',
            {
              'left-[280px] w-[calc(100%-280px)]': isFullMenu,
              'left-[60px] w-[calc(100%-60px)]': !isFullMenu,
            },
          )}>
          {children}
        </div>
        {isActiveLogoutModal && (
          <PopUpAlert
            isActive={isActiveLogoutModal}
            customIcon='logout'
            title='You are about to logout'
            message='Are you sure you want to proceed?'
            cancelButtonText='Cancel'
            confirmButtonText='Yes, I’m Sure'
            onCancelClick={cancelLogout}
            onOverlayClick={cancelLogout}
            onConfirmClick={confirmLogout}
          />
        )}
      </div>
    </>
  )
}

MainLayout.defaultProps = {
  hasSubHeading: true,
}

export default MainLayout
